





































import { Component, Vue } from "vue-property-decorator";
import { WriteOffForm } from "@/data/write-offs";
import WriteOffsResource from "@/resources/write-offs-resource";
import {
  getCommonStore,
  NotifyMutationPayload,
  NotificationType,
  INotification
} from "eka.core";

@Component
export default class WriteOffsView extends Vue {
  form = new WriteOffForm();
  loading = false;
  writeOffsId: string | null = null;
  code = "";
  retryTask: number = 0;
  retryCountdown: number = 60;

  async create(): Promise<void> {
    this.loading = true;
    try {
      await WriteOffsResource.create(this.form.request).then(res => {
        this.writeOffsId = res.id;
        this.startRetryTask();
      });
    } finally {
      this.loading = false;
    }
  }
  async confirm(): Promise<void> {
    this.loading = true;
    try {
      await WriteOffsResource.confirm({
        id: this.writeOffsId as string,
        code: this.code
      }).then(() => {
        getCommonStore().commit(
          new NotifyMutationPayload({
            message: "Зачисление произойдет в течение 24 часов",
            type: NotificationType.success,
            canClose: true,
            duration: 5000
          } as INotification)
        );
        this.$router.push({ name: "wallet-operations-list" });
      });
    } finally {
      this.loading = false;
    }
  }
  async retry(): Promise<void> {
    this.loading = true;
    try {
      await WriteOffsResource.resend(this.writeOffsId as string).then(() => {
        this.startRetryTask();
      });
    } finally {
      this.loading = false;
    }
  }

  get btnLabel(): string {
    return this.retryCountdown === 0
      ? (this.$i18n.t("notifications.retry") as string)
      : `${this.$i18n.t("notifications.retryAfter") as string} ${
          this.retryCountdown > 9
            ? this.retryCountdown
            : "0" + this.retryCountdown
        } ${this.$i18n.t("notifications.sek") as string}`;
  }
  get btnClasses(): string {
    return this.retryCountdown === 0
      ? "btn_primary btn_fluid"
      : "btn_primary btn_fluid";
  }
  get btnStyle(): string {
    return this.retryCountdown === 0
      ? ""
      : "pointer-events: none; opacity: 0.5";
  }

  startRetryTask(): void {
    clearInterval(this.retryTask);
    this.retryCountdown = 60;
    this.retryTask = setInterval(() => {
      if (this.retryCountdown !== 0) {
        this.retryCountdown -= 1;
      } else {
        clearInterval(this.retryTask);
      }
    }, 1000);
  }

  codeInput(e: Event) {
    if (this.loading) return;
    if (e && e.srcElement) {
      let inputValue: string = (e.srcElement as HTMLInputElement).value;
      if (inputValue.length === 6) {
        this.$nextTick(() => this.confirm());
      }
    }
  }

  beforeDestroy() {
    if (this.retryTask) clearInterval(this.retryTask);
  }
}
