import { BaseResource } from "./base-resource";
import {
  IWriteOff,
  IWriteOffConfirmRequest,
  IWriteOffCreationRequest
} from "@/data/write-offs";

export default class WriteOffsResource {
  static create(request: IWriteOffCreationRequest): Promise<IWriteOff> {
    return BaseResource.tryPost(WriteOffsUrls.create, request);
  }
  static confirm(request: IWriteOffConfirmRequest): Promise<void> {
    return BaseResource.tryPost(
      WriteOffsUrls.confirm.replace("{id}", request.id),
      request
    );
  }
  static resend(id: string): Promise<void> {
    return BaseResource.tryPost(WriteOffsUrls.resend.replace("{id}", id));
  }
}

enum WriteOffsUrls {
  create = "/v1/wallet/write-offs",
  confirm = "/v1/wallet/write-offs/{id}/confirm-by-code",
  resend = "/v1/wallet/write-offs/{id}/resend-code"
}
